import axios from 'axios';

import {LocalStorage} from '../../utils/localStorage';
import {parseToken} from '../../utils/parseToken';
import {LOCAL_STORAGE} from '../../constants/localStorage';
import {authProvider} from '../authProvider';

axios.interceptors.request.use(
  async config => {
    const accessToken = LocalStorage.get(LOCAL_STORAGE.TOKEN);
    config.headers.authorization = `Bearer ${accessToken}`;

    const dateNow = new Date().getTime();
    const {exp} = parseToken(accessToken);

    const isExpired = dateNow > exp * 1000;

    if (isExpired) {
      await authProvider.logout();
    }

    return config;
  },
  async error => Promise.reject(error)
);

axios.interceptors.response.use(null, async error => {
  const {status} = error.response;

  if (status === 401 || status === 403) {
    await authProvider.logout();
  }

  return Promise.resolve(error);
});

export class BaseApi {
  static token = LocalStorage.get(LOCAL_STORAGE.TOKEN);

  static get(route, {queryParams, headers, ...rest}) {
    return axios.get(route, {params: queryParams, headers, ...rest});
  }

  static post(route, {body, queryParams, headers, ...rest}) {
    return axios.post(route, body, {params: queryParams, headers, ...rest});
  }

  static put(route, {body, queryParams, headers, ...rest}) {
    return axios.put(route, body, {params: queryParams, headers, ...rest});
  }

  static patch(route, {body, queryParams, ...rest}) {
    return axios.patch(route, body, {params: queryParams, ...rest});
  }

  static getImage(identifier) {
    const url = `${process.env.REACT_APP_API}/file/${identifier}`;

    return this.get(url, {
      responseType: 'blob',
    }).then((resp) => URL.createObjectURL(resp.data));
  }
}
