import React from 'react';
import {
  Create,
  TextInput,
  NumberInput,
  TabbedForm,
  FormTab,
  required,
  ImageInput,
  ImageField,
  SelectInput,
} from 'react-admin'

import { Box } from '@material-ui/core';
import {ACCEPT_FILE} from "../../constants/filesFormat";

const CreateTruck = (props) => (
  <Create {...props}>
    <TabbedForm redirect={(basePath) => basePath}>
      <FormTab label="Main info">
        <ImageInput source="mainPhotoIdentifier" label="Main Picture" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="files" label="Files" accept={ACCEPT_FILE} multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <Box display="flex" p="1em" width="100% !important">
          <Box display="flex" flex={1} flexDirection='column' mr="1em">
            <TextInput source="name" label="Title" multiline validate={required()}/>
            <TextInput source="description" label="Description" multiline validate={required()}/>
            <NumberInput source="price" label="Price" validate={required()}/>
            <TextInput source="make" label="Make" validate={required()}/>
            <TextInput source="model" label="Model" validate={required()}/>
            <NumberInput source="year" label="Year" validate={required()}/>
            <NumberInput source="horsepower" label="Horse Power"/>
            <TextInput source="transSpeed" label="Trans Speed"/>
            <SelectInput source="trackType" choices={[
              { id: 'Sleeper', name: 'Sleeper' },
              { id: 'Day Cab', name: 'Day Cab' },
              { id: 'Dry Van', name: 'Dry Van' },
              { id: 'Reefer', name: 'Reefer' },
            ]}
             label="Truck Type"
             validate={required()}
            />
            {/*<TextInput source="trackType" label="Truck Type" validate={required()}/>*/}
          </Box>
          <Box display="flex" flex={1} flexDirection='column' mr="1em">
            <TextInput source="engineMake" label="Engine Make"  />
            <TextInput source="engineModal" label="Engine Model"  />
            <NumberInput source="engineHP" label="Engine HP"  />
            <TextInput source="transMake" label="Trans Make"  />
            <TextInput source="transModel" label="Trans Model"  />
            <TextInput source="suspensionType" label="Suspension Type"  />
            {/*---*/}
            <TextInput source="sleeperType" label="Sleeper Type"  />
            <TextInput source="wheelBase" label="Wheelbase"  />
            <TextInput source="fifthWheel" label="5th Wheel"  />
            <TextInput source="engineBrake" label="Engine Brake"  />
            <TextInput source="frameMaterial" label="Frame Material"  />
            {/*---*/}
            <TextInput source="color" label="Color"  />
            <TextInput source="interior" label="Interior"  />
            <TextInput source="serialNumber" label="Serial No."  />
            <NumberInput source="odometer" label="Odometer"  />
          </Box>
        </Box>
      </FormTab>
    </TabbedForm>
  </Create>
)

export default CreateTruck;
