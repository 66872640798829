import React, {useState} from 'react';
import {BaseApi} from "../api/services/baseApi";

const style = {
  imgFieldImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    marginRight: 10
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  icon: {
    width: 30,
  },
};

export const ImgField = React.memo(function ImgField({record = {}, source}) {
  const [image, setImage] = useState('');
  const [isFile, setIsFile] = useState(true);

  React.useEffect(() => {
    if (typeof (record) === "string") {
      setIsFile(false);
      (async () => {
        const resp = await BaseApi.getImage(record)
        setImage(resp)
      })();
    }
  }, [record]);

  return (
    <div style={style.imgFieldImg}>
      <img src={isFile ? record[source] : image} alt="title" style={style.img} />
    </div>
  );
});
