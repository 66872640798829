import React from 'react';
import {Edit, TextInput, TabbedForm, FormTab, required, ImageInput, SelectInput} from 'react-admin';
import {ImgField} from "../../common/ImgField";
import {ACCEPT_FILE} from "../../constants/filesFormat";

const EditTruck = (props) => (
  <Edit {...props} >
    <TabbedForm>
      <FormTab label="Main info">
        <TextInput source="name" label="Title" multiline validate={required()} />
        <TextInput source="description" label="Description" multiline validate={required()}  />
        <TextInput source="price" label="Price"  validate={required()} />
        <TextInput source="make" label="Make"  validate={required()} />
        <TextInput source="model" label="Model"  validate={required()} />
        <TextInput source="year" label="Year"  validate={required()} />
        <TextInput source="horsepower" label="Horse Power" />
        <TextInput source="transSpeed" label="Trans Speed" />
        <SelectInput source="trackType" choices={[
          { id: 'Sleeper', name: 'Sleeper' },
          { id: 'Day Cab', name: 'Day Cab' },
          { id: 'Dry Van', name: 'Dry Van' },
          { id: 'Reefer', name: 'Reefer' },
        ]}
         label="Truck Type"
         validate={required()}
        />
      </FormTab>
      <FormTab label="Additional info">
        <TextInput source="engineMake" label="Engine Make"  />
        <TextInput source="engineModel" label="Engine Model"  />
        <TextInput source="engineHP" label="Engine HP"  />
        <TextInput source="transMake" label="Trans Make"  />
        <TextInput source="transModel" label="Trans Model"  />
        <TextInput source="suspensionType" label="Suspension Type"  />

        <TextInput source="sleeperType" label="Sleeper Type"  />
        <TextInput source="wheelBase" label="Wheelbase"  />
        <TextInput source="fifthWheel" label="5th Wheel"  />
        <TextInput source="engineBrake" label="Engine Brake"  />
        <TextInput source="frameMaterial" label="Frame Material"  />

        <TextInput source="color" label="Color"  />
        <TextInput source="interior" label="Interior"  />
        <TextInput source="serialNumber" label="Serial No."  />
        <TextInput source="hourse" label="Hours"  />
        <TextInput source="odometer" label="Odometer"  />
      </FormTab>
      <FormTab label='Files'>
        <ImageInput
          source="mainPhotoIdentifier"
          labelSingle='Drop a photo to upload, or press to select. Maximum one photo'
          label="Files"
          accept={ACCEPT_FILE}
          validate={required()}
      >
          <ImgField source="src" title="title" />
        </ImageInput>
        <ImageInput source="attachments" label="Files" accept={ACCEPT_FILE} multiple>
          <ImgField source="src" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)
export default EditTruck;
