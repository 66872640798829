import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required
} from 'react-admin'

const redirect = (basePath) => basePath;

const CreatePosition = (props) => (
  <Create {...props} >
    <SimpleForm redirect={redirect}>
      <TextInput source="name" label="Position title" validate={required()}/>
      <TextInput source="description" label="Description" multiline validate={required()}/>
      <TextInput source="positionName" label="Position"  validate={required()}/>
      <TextInput source="location" label="Location" validate={required()}/>
      <TextInput source="shift" label="Shift" validate={required()}/>
      <TextInput source="salary" label="Salary" validate={required()}/>
      <TextInput source="experience" label="Experience" validate={required()}/>
      <TextInput source="requirements" label="Requirements"/>
    </SimpleForm>
  </Create>
)

export default CreatePosition;
