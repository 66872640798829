import {BaseApi} from './baseApi';

export class AuthApi extends BaseApi {
  static auth(username, password) {
    const url = `${process.env.REACT_APP_API}/Authorization`;

    return this.post(url, {
      queryParams: {
        UserName: username,
        Password: password,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(({data}) => data);
  }


}
