import React from "react";
import {Admin, Resource} from "react-admin";

import PeopleIcon from '@material-ui/icons/People';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import ViewPosition from './component/position/View'
import {authProvider} from "./api/authProvider";
import {dataProvider} from "./api/dataProvider";
import EditPosition from "./component/position/Edit";
import CreatePosition from "./component/position/Create";
import ViewTruck from "./component/truck/View";
import CreateTruck from "./component/truck/Create";
import EditTruck from "./component/truck/Edit";

const App = () => (
  <>
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="VLine-admin"
    >
      <Resource
        name='position'
        list={ViewPosition}
        create={CreatePosition}
        edit={EditPosition}
        options={{label: 'Careers'}}
        icon={PeopleIcon}
      />
      <Resource
        name='track'
        list={ViewTruck}
        create={CreateTruck}
        edit={EditTruck}
        options={{label: 'Inventory'}}
        icon={LocalShippingIcon}
      />
    </Admin>
  </>
)

export default App;
