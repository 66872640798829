import React from 'react';
import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';

const ViewTruck = props => (
  <List
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Title" />
      <TextField source="serialNumber" label="Serial number" />
    </Datagrid>
  </List>
)

export default ViewTruck
