import {BaseApi} from './baseApi';
import axios from "axios";

export class DataProviderApi extends BaseApi {
  static getList(url, queryParams) {
    return this.get(url, {queryParams}).then(({data}) => data);
  }
  static getOne(url) {
    return this.get(url, {queryParams: ''}).then(({data}) => data);
  }
  static getMany(url, queryParams) {
    return this.get(url, {queryParams}).then(({data}) => data);
  }
  static update(url, body) {
    return this.put(url, {body}).then(({data}) => data);
  }
  static create(url, body) {
    return this.post(url, {body}).then(({data}) => data);
  }
  static uploadImage = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${process.env.REACT_APP_API}/file`, formData)
  }
}
