import {LocalStorage} from '../utils/localStorage';
import {parseToken} from '../utils/parseToken';
import {LOCAL_STORAGE} from '../constants/localStorage';
import {AuthApi} from "./services/authApi";

export const authProvider = {
  login: async (params = {}) => {
    if (!params) {
      return Promise.reject();
    }

    const {username, password} = params;

    const {accessToken} = await AuthApi.auth(username, password);

    LocalStorage.set(LOCAL_STORAGE.TOKEN, accessToken);
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = LocalStorage.get(LOCAL_STORAGE.TOKEN);

    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  logout: () => {
    LocalStorage.clear();

    return Promise.resolve();
  },
  getPermissions: () => {
    const token = LocalStorage.get(LOCAL_STORAGE.TOKEN);

    const parse = parseToken(token);

    const isManager = Object.values(parse).flat().includes('Manager');

    return token ? Promise.resolve(!isManager) : Promise.reject();
  },
};
