import React from 'react';
import {Edit, required, SimpleForm, TextInput} from 'react-admin';

const EditPosition = (props) => (
  <Edit {...props} >
    <SimpleForm >
      <TextInput source="name" label="Position title" validate={required()}/>
      <TextInput source="description" label="Description" multiline validate={required()}/>
      <TextInput source="positionName" label="Position"  validate={required()}/>
      <TextInput source="location" label="Location" validate={required()}/>
      <TextInput source="shift" label="Shift" validate={required()}/>
      <TextInput source="salary" label="Salary" validate={required()}/>
      <TextInput source="experience" label="Experience" validate={required()}/>
      <TextInput source="requirements" label="Requirements"/>
    </SimpleForm>
  </Edit>
)

export default EditPosition;
