import React from 'react';
import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';

const ViewPosition = props => (
  <List
    {...props}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Position" />
      <TextField source="location" label="Location" />
      <TextField source="shift" label="Shift" />
      <TextField source="salary" label="Salary" />
      <TextField source="experience" label="Experience" />
      <TextField source="requirements" label="Requirements" />
    </Datagrid>
  </List>
)

export default ViewPosition
