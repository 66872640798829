import axios from "axios";

import { DataProviderApi } from './services/dataPrividerApi';

const URL = `${process.env.REACT_APP_API}`;

export const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;

    const query = {
      SortOrder: 'CreatedAt',
      Page: page,
      PerPage: perPage,
    };

    const url = `${URL}/${resource}`;
    const { data, total } = await DataProviderApi.getList(url, query);

    return { data, total };
  },

  getOne: async (resource, params) => {
    const url = `${URL}/${resource}/${params.id}`;
    const resp = await DataProviderApi.getOne(url);

    return { data: resp };
  },

  update: async (resource, params) => {
    const { id, data } = params;
    const uploadFiles = [];
    let uploadMainPhotoIdentifier = null;
    const mainPhoto = params.data?.mainPhotoIdentifier
    const files = params.data?.attachments

    const newMainPicture = mainPhoto?.rawFile instanceof File;

    const newPictures = files?.filter(p => p.rawFile instanceof File);
    const formerPictures = files?.filter(p => !(p.rawFile instanceof File));

    if (files) {
      await Promise.all(newPictures?.map(({ rawFile }) => DataProviderApi.uploadImage(rawFile)))
        .then(resp => resp.forEach(({ data: { identifier } }) => uploadFiles.push(identifier)))
      if (newMainPicture) {
        await DataProviderApi.uploadImage(mainPhoto.rawFile).then(({ data: { identifier } }) => uploadMainPhotoIdentifier = identifier)
      }
    }

    const url = `${URL}/${resource}/${id}`;
    const resp = await DataProviderApi.update(url, {
      ...data,
      mainPhotoIdentifier: newMainPicture ? uploadMainPhotoIdentifier : mainPhoto,
      attachments: [...(uploadFiles || []), ...(formerPictures || [])]
    });
    return { data: { ...data, id: resp.id } };
  },

  create: async (resource, params) => {
    const { data } = params;
    const uploadFiles = [];
    let mainPhoto = null;
    const mainPhotoIdentifier = params.data?.mainPhotoIdentifier
    const files = params.data?.files

    if (files || mainPhotoIdentifier) {
      await DataProviderApi.uploadImage(mainPhotoIdentifier.rawFile).then(({ data: { identifier } }) => mainPhoto = identifier)
      if (files) await Promise.all(files?.map(({ rawFile }) => DataProviderApi.uploadImage(rawFile)))
        .then(resp => resp.forEach(({ data: { identifier } }) => uploadFiles.push(identifier)))
    }

    const url = `${URL}/${resource}`;
    const resp = await DataProviderApi.create(url, { ...data, attachments: uploadFiles, mainPhotoIdentifier: mainPhoto });
    return { data: { ...data, id: resp.id } };
  },

  delete: async (resource, params) => {
    const { id } = params;
    const url = `${URL}/${resource}/${id}`;
    const resp = await axios.delete(url);
    return { data: resp };
  }
};


